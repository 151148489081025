<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
        <h4>{{ Object.entries(this.data).length === 0 ? "Agregar" : "Actualizar" }} Tienda</h4>
        <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">

      <div class="p-6">

        <div class="mt-4">
          <label>Tipo de Tienda</label>
          <div class="flex flex-wrap mt-3 mb-6">
            <vs-radio v-model="dataCommerceType" vs-value="1" class="mr-4">Escuela</vs-radio>
            <vs-radio v-model="dataCommerceType" vs-value="2" class="mr-4">Comercio</vs-radio>
          </div>
        </div>

        <!-- SCHOOL -->
        <div class="mt-5" v-if="dataCommerceType == '1'">
          <label class="vs-input--label">Escuela</label>
          <v-select browserAutocomplete="off" autocomplete="off" v-model="dataIdSchool" :reduce="dataIdSchool => dataIdSchool.value" :options="school_choices" @input="suggestStoreName()" :clearable="false" v-validate="'required'" name="item-id_school" :dir="$vs.rtl ? 'rtl' : 'ltr'" data-vv-as="Escuela"/>
          <span class="text-danger text-sm" v-show="errors.has('item-school')">{{ errors.first('item-id_school') }}</span>
        </div>

        <!-- NAME -->
        <vs-input label="Nombre de la Tienda" autocomplete="off" v-model="dataName" class="mt-5 w-full" name="item-name" v-validate="'required|max:50'" data-vv-as="Nombre de la Tienda"/>
        <span class="text-danger text-sm" v-show="errors.has('item-name')">{{ errors.first('item-name') }}</span>

        <!-- Comisión Tiptap -->
        <vs-input
          label="Comisión Tiptap"
          v-model="dataTiptapCommission"
          class="mt-5 w-full"
          name="item-tiptap-commission"
          data-vv-as="Comisión Tiptap"/>
        <span class="text-danger text-sm" v-show="errors.has('item-tiptap-commission')">{{ errors.first('item-tiptap-commission') }}</span>

        <!-- DESCRIPTION -->
        <!-- <vs-input label="Descripción" autocomplete="off" v-model="dataDescription" class="mt-5 w-full" name="item-description" v-validate="'required|max:100'" />
        <span class="text-danger text-sm" v-show="errors.has('item-description')">{{ errors.first('item-description') }}</span> -->

      </div>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid">Guardar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import vSelect from 'vue-select'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {},
    }
  },
  watch: {
    isSidebarActive(val) {
      if(!val) return
      if(Object.entries(this.data).length === 0) {
        this.initValues()
        this.$validator.reset()
      }else {
        let { id, name, status } = JSON.parse(JSON.stringify(this.data))
        this.dataId = id
        this.dataName = name
        this.dataStatus = status
        this.initValues()
      }
      // Object.entries(this.data).length === 0 ? this.initValues() : { this.dataId, this.dataName, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
    }
  },
  data() {
    return {

      dataId: null,
      dataName: "",
      dataStatus : 1,
      dataCommerceType: 1,
      dataTiptapCommission: 0,
      dataIdSchool: null,

      school_choices: [],

      settings: { // perfectscrollbar settings
          maxScrollbarLength: 60,
          wheelSpeed: .60,
      },
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if(!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid() {
      return !this.errors.any() && this.dataName
    }
  },
  methods: {
    initValues() {
      if(this.data.id) return
        this.dataId = null
        this.dataName = ""
        this.dataStatus = 1
    },
    submitData() {
      this.$validator.validateAll().then(result => {
          if (result) {
            const obj = {
              id_category: this.dataId,
              name: this.dataName
            }

            if(this.dataId !== null && this.dataId >= 0) {
              this.$store.dispatch("marketplace/updateStore", obj)
              .then(response => {
                if(response.status)
                  this.showAddSuccess()
                  this.$emit('closeSidebar')
                  this.initValues()
              })
              .catch(error => {
                this.showAddError(error)
              })
            } else {
              delete obj.id_category
              this.$store.dispatch("marketplace/addStore", obj)
              .then(response => {
                if(response.status)
                  this.showAddSuccess()
                  this.$emit('closeSidebar')
                  this.initValues()
              })
              .catch(error => {
                this.showAddError(error)
              })
            }
          }
      })
    },
    getSchoolChoices() {
      if(this.school_choices && this.school_choices.length) return

      const schools = this.$store.getters['dataListInstitutions/getSchools'];
      const stores = this.$store.getters['marketplace/getStores']

      const available_schools = schools.filter(({ id: id1 }) => !stores.some(({ id: id2 }) => id2 === id1));

      available_schools.forEach(element => {
        this.school_choices.push({label: element.name, value: element.id})
      });
    },
    suggestStoreName() {
      const suggested_name = this.school_choices.find(s => s.value === this.dataIdSchool).label

      this.dataName = `Tienda ${suggested_name}`
    },
    showAddSuccess() {
      this.$vs.notify({
        color: 'success',
        title: 'Categoría',
        text: 'Se han guardado los cambios.',
        time: 8000,
        position: 'top-center',
        iconPack: 'feather',
        icon:'icon-check',
      })
    },
    showAddError(msg) {
      this.$vs.notify({
        color: 'danger',
        title: 'Categoría',
        text: msg || 'Error al guardar los cambios.',
        time: 8000,
        position: 'top-center',
        iconPack: 'feather',
        icon:'icon-alert-circle',
      })
    },
  },
  components: {
    VuePerfectScrollbar,
    'v-select': vSelect,
  },
  mounted() {
    this.getSchoolChoices()
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
